<script>
	import GlobalVue from '@helper/Global.vue';
	// import Gen from '@helper/Gen';
	// import TopBarVue from './TopBar.vue';

	export default {
		extends: GlobalVue,
		data() {
			return {
			}
		},
		async mounted() {
			await this.ready();
			global.Header = this
		},
	};
</script>

<template>
	<header id="header" class="clearfix header-transaparent">
		<div id="header-wrap">
			<div class="container clearfix">
				<div id="primary-menu-trigger"><i class="icon-reorder"></i></div>
				<!-- Logo -->
				<div id="logo" class="logo_custom">
					<router-link :to="{name:'Home'}" class="standard-logo" :data-dark-logo="baseUrl+'/frontend/images/logo.png'">
						<VImg :src="baseUrl+'/frontend/images/vvl_logo.png'" alt="Logo Visi Viyata Lestari" title="Logo Visi Viyata Lestari"></VImg>
					</router-link>

					<router-link :to="{name:'Home'}" class="retina-logo" :data-dark-logo="baseUrl+'/frontend/images/logo.png'">
						<VImg :src="baseUrl+'/frontend/images/vvl_logo.png'" alt="Logo Visi Viyata Lestari" title="Logo Visi Viyata Lestari"></VImg>
					</router-link>
				</div>
				<!-- #logo end -->
				
				<nav id="primary-menu" class="d-lg-flex d-xl-flex justify-content-xl-between justify-content-lg-between fnone with-arrows">
					<ul class="align-self-start">
						<li><span class="menu-bg col-auto align-self-start d-flex"></span></li>
						<li :class="$route.name=='Home' ? 'active' :''">
							<router-link :to="{name:'Home'}"><div>{{ web.mn_home }}</div></router-link>
						</li>
						<li :class="$route.name=='ProfilYayasan' ? 'active' :''">
							<router-link :to="{name:'ProfilYayasan'}"><div>{{ web.mn_profile }}</div></router-link>
						</li>
						<li :class="$route.name=='Program' ? 'active' :''">
							<router-link :to="{name:'Program'}"><div>{{ web.mn_program }}</div></router-link>
						</li>
						<li :class="$route.name=='News' ? 'active' :''">
							<router-link :to="{name:'News'}"><div>{{ web.mn_berita }}</div></router-link>
						</li>
						<li :class="$route.name=='Contact' ? 'active' :''">
							<router-link :to="{name:'Contact'}"><div>{{ web.mn_contact }}</div></router-link>
						</li>
					</ul>
					<ul class="not-dark align-self-end">
						<li>
							<a href="#">
								<div><img class="flag" :src="assets('fo_images',(hl=='id'?'id':'en')+'.png')" :alt="hl=='en'?'English':'Bahasa'"></div>
							</a>
							<ul>
								<!-- <li>
									<router-link :to="{query:{hl:'en'}}">
										<img class="flag" :src="assets('fo_images','en.png')" alt="English"> English
									</router-link>
								</li> -->
								<li>
									<router-link :to="{query:{hl:'id'}}">
										<img class="flag" :src="assets('fo_images','id.png')" alt="Bahasa"> Bahasa
									</router-link>
								</li>
							</ul>
						</li>
					</ul>
				</nav>
			</div>
		</div>
	</header>
</template>